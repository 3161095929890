//   在http.js中引入axios
import axios from "axios"; // 引入 axios
import { Message } from "element-ui"; // 引入 element-ui 的 Message 模块，用于信息提示

// create an axios instance   创建axios实例
const service = axios.create({
  // baseURL: "/", // api 的 base_url
  // baseURL: "/api", // 本地测试用
  timeout: 5000, // request timeout  设置请求超时时间
  validateStatus: function (status) {
    return status >= 200 && status < 500; // 默认的
  },
  //   responseType: "json",
  //   withCredentials: true, // 是否允许带cookie这些
  //   headers: {
  //     "Content-Type": "application/json;charset=utf-8",
  //   },
});

service.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => {
    console.log(err);
    // 请求错误的常见状态码：4XX  401-请求超时  404-mot found
    Message({
      message: err.response.data.message,
      duration: 1500,
      forbidClick: true,
    });
    return Promise.reject(err);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    console.log(res);
    // 在请求成功后的数据处理
    return { res, status: res.status, data: res.data };
  },
  (err) => {
    console.log(err);
    Message({
      message: err,
      duration: 1500,
      forbidClick: true,
    });
    // 在响应错误的时候的逻辑处理
    return Promise.reject(err);
  }
);

// // response interceptor
// service.interceptors.response.use(
//   (response) => {
//     console.log("请求成功！");
//     console.log(response);
//     // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
//     // 否则的话抛出错误
//     if (response.status === 200) {
//       return Promise.resolve(response);
//     } else {
//       return Promise.reject(response);
//     }
//   },
//   // 服务器状态码不是2开头的的情况
//   // 这里可以跟你们的后台开发人员协商好统一的错误状态码
//   // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
//   // 下面列举几个常见的操作，其他需求可自行扩展
//   (error) => {
//     console.log("请求失败！");
//     console.log(error);
//     if (error.response.status) {
//       switch (error.response.status) {
//         // // 401: 未登录
//         // // 未登录则跳转登录页面，并携带当前页面的路径
//         // // 在登录成功后返回当前页面，这一步需要在登录页操作。
//         // case 401:
//         //     router.replace({
//         //         path: '/login',
//         //         query: {
//         //             redirect: router.currentRoute.fullPath
//         //         }
//         //     });
//         //     break;

//         // // 403 token过期
//         // // 登录过期对用户进行提示
//         // // 清除本地token和清空vuex中token对象
//         // // 跳转登录页面
//         // case 403:
//         //     Message({
//         //         message: '登录过期，请重新登录',
//         //         duration: 1000,
//         //         forbidClick: true
//         //     });
//         //     // 清除token
//         //     localStorage.removeItem('token');
//         //     store.commit('loginSuccess', null);
//         //     // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
//         //     setTimeout(() => {
//         //         router.replace({
//         //             path: '/login',
//         //             query: {
//         //                 redirect: router.currentRoute.fullPath
//         //             }
//         //         });
//         //     }, 1000);
//         //     break;

//         // 404请求不存在
//         case 404:
//           Message({
//             message: "网络请求不存在",
//             duration: 1500,
//             forbidClick: true,
//           });
//           break;
//         // 其他错误，直接抛出错误提示
//         default:
//           Message({
//             message: error.response.data.message,
//             duration: 1500,
//             forbidClick: true,
//           });
//       }
//       return Promise.reject(error.response);
//     }
//   }
// );

export default service;
