import service from "./interceptors";
import { Message } from "element-ui"; // 引入 element-ui 的 Message 模块，用于信息提示
// import service from "axios";

export const requestLogin = (params) => {
  return service.post(`/login`, params).then((res) => res.data);
};

export const getReceipt = (params) => {
  return service.get(`/api/mts/receipt`, {
    params: params,
  });
};

export const getReceiptData = (id, params) => {
  return service.get(`/api/mts/order/approval/${id}`, {
    params: params,
  });
};


// export const updateRFQ = (params) => {
//   return service.put(`/api/mtc/rfq`, {
//     params: params,
//   });
// };

// export const acceptRFQ = (params) => {
//   return service.post(`/api/mtc/rfq`, {
//     params: params,
//   });
// };

// export const getPO = (params) => {
//   return service.get(`/api/mtc/po`, {
//     params: params,
//   });
// };

// export const updatePO = (params) => {
//   return service.put(`/api/mtc/po`, {
//     params: params,
//   });
// };

// export const acceptPo = (params) => {
//   return service.post(`/api/mtc/po`, {
//     params: params,
//   });
// };

// export const deleteAttr = (params) => {
//   return service.delete(`/api/mtc/attr`, {
//     params: params,
//   });
// };

// export const getAttrList = (params) => {
//   return service.get(`/api/mtc/attr`, {
//     params: params,
//   });
// };

// export const uploadAttrList = (params) => {
//   return service.post(`/api/mtc/attr`, params, {
//     timeout: 30000,
//     headers: {
//       "Content-Type": "multipart/form-data"
//     }
//   });
// };

// export const downloadAttr = (params) => {
//   return service.get(`/api/mtc/attr/download`, {
//     params: params,
//     responseType: 'blob'
//   });
// };
export const
  successMessage = (text) => {
    Message({
      message: text,
      type: "success",
    });
  };
export const
  errorMessage = (text) => {
    Message({
      message: text,
      type: "error",
    });
  };