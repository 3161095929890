<template>
  <div class="Recepit">
    <!-- <div class="accordion" navbar-static top role="tablist">
      
    </div> -->
    <b-card no-body class="mb-1">
        <b-card-header id="top1" header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-1 variant="info"
            >单据详情</b-button
          >
        </b-card-header>
        <b-collapse
          id="accordion-1"
          visible
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <!-- <b-card-text
              >I start opened because <code>visible</code> is
              <code>true</code></b-card-text
            > -->
            <b-card-text>
              <div v-for="(value, name) in hearder" :key="name">
                <p>{{ name }}</p>
                <p class="value">{{ value }}</p>
              </div>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

    <b-card no-body class="mb-1">
      <b-card-header id="top2" header-tag="header" style="z-index: 1;" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="info"
          >单据明细</b-button
        >
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accordion1" role="tabpanel">
        <b-card-body>
          <b-card-text class="card-text">
            <el-table

              :data="tableData"
              style="width: 100%"
              row-class-name="123"
            >
              <el-table-column
                v-for="(value, name) in tableData[0]"
                :key="value"
                :prop="name"
                :label="name"
                width=""
              >
              </el-table-column>
              <!-- <el-table-column prop="date" label="日期" width="">
              </el-table-column>
              <el-table-column prop="name" label="姓名" width="">
              </el-table-column>
              <el-table-column prop="address" label="地址"> </el-table-column> -->
            </el-table>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

    <br />
    <br />
    <br />
    <!-- <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="html">
    </el-input> -->

    <div class="float">
      <el-button @click="test" type="warning">取消</el-button>
      <el-button type="primary">通过</el-button>
      <el-button type="danger">拒绝</el-button>
    </div>
  </div>
</template>
<script>
import stickybits from "stickybits";
import { getReceiptData, errorMessage } from "../api/api";
export default {
  created() {
    var lang = navigator.language || navigator.userLanguage; // 常规浏览器语言和IE浏览器
    lang = lang.substr(0, 2); // 截取lang前2位字符
    console.log(lang);
    if (lang === "zh") {
      this.locale = "zh_cn";
    } else {
      this.locale = "en";
    }
  },
  mounted() {
    // console.log(window.document.querySelector('#top1'));
    stickybits("#top1",{parentClass:".Recepit"}); 
    stickybits("#top2");// stickybits('#top2',{scrollEl:'.card-text'})
  },
  data() {
    return {
      locale: "",
      text: "123",
      html: "",
      hearder: {
        date: "2016-05-02",
        name: "王小虎",
        address: "上海市普陀区金沙江路 1518 弄",
        1: "2016-05-02",
        n2ame: "王小虎",
        ad3dress: "上海市普陀区金沙江路 1518 弄",
        d4ate: "2016-05-02",
        n5ame: "王小虎",
        a6ddress: "上海市普陀区金沙江路 1518 弄",
        d7ate: "2016-05-02",
        na8me: "王小虎",
        ad9dress: "上海市普陀区金沙江路 1518 弄",
      },
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
    };
  },
  watch: {
    locale(val) {
      this.$i18n.locale = val;
    },
  },
  methods: {
    test() {
      getReceiptData(64, { userpkno: 1 })
        .then((rep) => {
          // console.log(rep);
          this.hearder = rep.data.HEADER[0];
          // console.log(rep.data.HEADER[0]);
          // for (const pname in this.hearder) {
          //   // x 为属性名
          //   console.log(pname, this.hearder[pname]);
          // }

          // console.log(rep.data.LINES);
          this.tableData = rep.data.LINES;
        })
        .catch((err) => {
          console.log(err);
          errorMessage(err);
        });
    },
  },
};
</script>
<style scoped>
.float {
  left: 0;
  position: fixed;
  bottom: 20px;
  width: 100%;
  z-index: 100;
}
.value {
  text-decoration-line: underline;
}
.aaa {
  top: 20px;
}
</style>
